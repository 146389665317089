import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/App/Category/Category.vue'
import CategoryDetail from '../views/App/CategoryDetail/CategoryDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/category/:categoryId',
    name: 'CategoryDetail',
    component: CategoryDetail,
    afterRouteEnter() {
      alert('route enter')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    } else {
      return { x: 0, y: 0 }
      // return setTimeout(() => {
      // }, 20)
    }
  }
})

export default router
