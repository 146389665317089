<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch('fetchMenu')
    this.$store.dispatch('fetchMenuapi')
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,200;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400&display=swap');
:root {
  --foreground: #1d242d;
  --white-foreground: #f7f5f5;
  --dark: #1d242d;
  --foreground: #fff;
  --nav-height: 80px;
}

body,
html {
  padding: 0;
  margin: 0;
  height: 100vh;
  background: var(--foreground);
  font-family: 'Raleway', sans-serif;
}

body {
  display: grid;
  grid-template-rows: repeat(12, 1fr);
  padding: 3em;
}

a {
  text-decoration: none;
}

#app {
  padding: 0;
  margin: 0;
  height: 100vh;
}

.clearfix {
  overflow: auto;
}

.flex {
  display: flex;
}

.flex-item {
}

.flex-11 {
  flex: 11;
}

.flex-1 {
  flex: 1;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  body {
    padding: 0em;
  }
}

/* Animation */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
